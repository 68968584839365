import { graphql } from "gatsby"
import React, { useContext } from "react"
import PageWrapper from "../components/SiteWrapper"
import { GatsbyImageType } from "../shared/ContentfulTypes"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { device } from "../shared/Device"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import * as translations from "../utils/translations"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.data?.target?.file?.fileName
      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },

    [INLINES.HYPERLINK]: (node: any) => {
      return (
        <a href={node?.data?.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node: any) => {
      const url = node?.data?.target?.file?.url
      const name = node?.content[0].value

      return (
        <a href={url} target="_blank">
          {name}
        </a>
      )
    },
  },
}

type Props = {
  data: {
    contentfulEvent: {
      heading: string
      eventDate: string
      eventEndDate: string
      description: { description: string }
      eventImage: GatsbyImageType
      textField: any
    }
  }
}

const NewsPage: React.FC<Props> = ({ data }) => {
  const { heading, eventDate, eventEndDate, eventImage, textField } =
    data.contentfulEvent
  const richText = textField

  const { locale } = useContext(LocaleContext) as ILocaleContext
  const eventTranslations =
    locale === "sv" ? translations.weekDays.sv : translations.weekDays.en

  const weekDays = [
    eventTranslations.monday,
    eventTranslations.tuesday,
    eventTranslations.wednesday,
    eventTranslations.thursday,
    eventTranslations.friday,
    eventTranslations.saturday,
    eventTranslations.sunday,
  ]

  const startDateNumber = eventDate.split("T")[0]

  let startDate = new Date(eventDate)
  const indexPosition = startDate.getDay() - 1
  const startWeekDay =
    indexPosition === -1 ? weekDays[6] : weekDays[startDate.getDay() - 1]
  const startTime = eventDate?.split("T")[1].split("+")[0]
  const endTime = eventEndDate?.split("T")[1].split("+")[0]

  return (
    <PageWrapper
      seo={{ seoTitle: heading, seoDescription: heading, slug: heading }}
    >
      <SectionWrapper>
        <StyledImage>
          <GatsbyImage
            image={eventImage?.gatsbyImageData}
            alt={eventImage?.description || "missing"}
            className="topImage"
          />
          <DateContainer>
            <DateInfo>{`${startWeekDay} ${startDateNumber} kl. ${startTime}  ${
              endTime ? "- " + endTime : ""
            }`}</DateInfo>
          </DateContainer>
        </StyledImage>

        <TextContainer>
          <Heading>{heading}</Heading>
          {richText ? (
            <Text>{renderRichText(richText, richTextOptions)}</Text>
          ) : (
            <p>Ingen data</p>
          )}
        </TextContainer>
        <PageFooter>
          <PageFooterText />
        </PageFooter>
      </SectionWrapper>
    </PageWrapper>
  )
}
export const query = graphql`
  query MyEventPageQuery($id: String) {
    contentfulEvent(id: { eq: $id }) {
      node_locale
      slug
      heading
      textField {
        raw
        references {
          __typename
          contentful_id
          file {
            url
            fileName
          }
        }
      }
      eventDate
      eventEndDate
      eventImage {
        gatsbyImageData
      }
    }
  }
`

const SectionWrapper = styled.div`
  position: relative;
  background: var(--ud-white);
  margin: 0 auto;

  a {
    color: var(--hyperlink-blue);
    font-weight: bold;
  }

  @media ${device.laptop} {
    max-width: 53rem;
  }
`
const StyledImage = styled.div`
  max-width: var(--hero-max-width);
  position: relative;

  .topImage {
    height: 0;
    padding-bottom: 49.25946633%;
    aspect-ratio: unset;

    @supports (aspect-ratio: 2.03 / 1) {
      height: auto;
      padding-bottom: 0;
      aspect-ratio: 2.03 / 1;
    }

    @media ${device.desktop} {
      height: 0;
      aspect-ratio: unset;
      padding-bottom: 43.49702073%;

      @supports (aspect-ratio: 2.299 / 1) {
        height: auto;
        padding-bottom: 0;
        aspect-ratio: 2.299 / 1;
      }
    }
  }
`
const DateContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background: var(--ud-beige);
  white-space: nowrap;
  transform: rotate(-2deg);
  top: 90%;
  height: 4.75rem;
  width: 17.68rem;

  @media ${device.tabletM} {
    top: 90%;
    left: 0;
    height: 5.75rem;
    width: 20.5rem;
  }

  @media ${device.laptop} {
    top: 92%;
    bottom: 0;
    height: 4.7rem;
    width: 19.56rem;
  }
`

const DateInfo = styled.p`
  margin: 0 auto;
  font-weight: bold;

  @media ${device.laptop} {
    font-size: 1rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8rem 1rem 4rem 1rem;

  @media ${device.laptop} {
    padding: 5.9rem 7rem 10rem 7rem;
  }
`
const Heading = styled.h3`
  text-align: center;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    font-size: 3.5rem;
  }
`
const Text = styled.p`
  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptop} {
    font-size: 1.12rem;
  }
`

const PageFooter = styled.div`
  display: flex;
  align-items: center;
  height: 4.75rem;
  background: var(--ud-beige);
  margin-bottom: 8.5rem;
`
const PageFooterText = styled.p`
  font-size: 0.87rem;
  padding: 0 1.31rem 0 1rem;

  @media ${device.laptop} {
    padding-left: 4rem;
  }

  @media ${device.tablet} {
    font-size: 1.3rem;
  }
`
export default NewsPage
